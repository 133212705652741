import Layout from "components/layout"
import Markdown from "components/markdown"
import React from "react"

const md = `
# Terms of Use

Welcome to Stay or Go! We hope you find our website valuable and informative. Before you browse our website (**Site**), please read these Terms of Use (Terms), as they govern the use of our Site and set out your rights and responsibilities when using our Site.

This Site is operated by Stay or Go Pty Ltd ABN 50 637 744 613 trading as Stay or Go (**we**, **our** or **us**). Stay or Go holds an Australian Credit Licence (Number: 527092). The Site is available at: [stayorgo.com.au](/) and may be available through other addresses or channels.

### How you consent to these terms of use

By using our Site, you are taken to have accepted these Terms, warrant to us that you have reviewed and understand these Terms, and agree to use the Site in accordance with the Terms.

Please read these Terms carefully – if you don’t agree to them, then you must cease using our Site immediately.

### When we make changes to these terms of use

We may, at any time and at our discretion, vary these Terms by publishing the varied Terms on our Site. We recommend you check our Site regularly to ensure you are aware of our current terms. Your continued use of the Site after any variation to our Terms will be deemed to be your acceptance of our varied Terms.

### Privacy

We respect your privacy and understand protecting your personal information is important. Our [Privacy Policy](/privacy) sets out how we will collect and handle your personal information.

### Your licence to use our Site

We grant you a non-exclusive, royalty-free, revocable, worldwide, non-transferable licence to use our Site in accordance with these Terms. All other uses are prohibited without our prior written consent.

### Our Services - Individual Users

Stay or Go helps you get a better deal on your credit products, either with your current credit issuer or a new one. We do this by providing information about, and comparisons of, credit products in the Australian market. We do not compare every credit product in the market. We are not a credit provider and we do not provide credit assistance. When we provide information via our Site, we are not providing you with a recommendation about a particular credit product. We do not suggest you apply for, remain in, or increase the credit limit of, a particular credit product. We do not assist you to apply for a particular credit product. 

If you decide to apply for or change a credit product, you will deal directly with the credit issuer. You will be required to sign an agreement in order to enter into a credit product with the credit issuer. If there is any inconsistency between the agreement and these Terms, the agreement will prevail to the extent of the inconsistency.

The credit issuer and its representatives are not our employees, contractors or agents and we are not a party to any agreement between you and the card issuer and have no control over the conduct of the card issuers.

We may receive fees or other benefits in relation to the activity on the Site. Please refer to our [how we make money](#how-we-make-money) for more information. 

### Our Services - Commercial Users

Our commercial partners such as credit providers, can also access the platform to upload and review details about their promotions, products and services. As a commercial partner, you agree to only access and use our platform in accordance with these Terms and only for the purposes contemplated in our Service Agreement with you. 

### Content disclaimer

Materials, information, and benefit estimates on this Site (Content) are subject to change without notice.

We endeavour to ensure that the information on our Site is current and accurate however, before entering into any credit product with a credit issuer displayed on our Site, you should confirm the fees, rates and product information with the credit issuer. We do not undertake to keep our Site up-to-date and we are not liable if any Content is inaccurate or out-of-date.

When you input your personal details in order to review offers and an estimate of any potential savings and benefits that may be available to you, any information provided to you is merely an estimate, and is not guaranteed. While we use reasonable attempts to ensure the accuracy and completeness of our Content, we make no representation or warranty in relation to it, to the maximum extent permitted by law.

The Content is for general information purposes only. It does not take into account your specific needs, objectives or financial circumstances and does not amount to financial advice, legal advice or tax advice. You cannot rely on it as such. Some information has been provided by external providers and is believed to be accurate and current at the date of publication. Full terms and conditions of the consumer credit products should be obtained from the relevant credit issuer. We provide this information in good faith, make no representation or warranty in relation to it, and are not liable for any loss arising from reliance on this information, including reliance on information that is no longer current or predictions which are based on current expectations about future events.

Predictions based on current expectations about future events, are based on opinions that we hold only at the date of publication and may be based on information provided by external parties. They are not guarantees of future performance or events, but are subject to a number of factors that are not within our control and as a result may differ from actual outcomes. We are not liable for these outcomes, make no representation or warranty in relation to them and offer these predictions in good faith. You should seek appropriate professional advice before making any decisions about a credit product.

### How we make money

Stay or Go is committed to providing its service to consumers free of charge.

We generate revenue by offering third-parties the ability to advertise their products on our Site, including credit providers and brokers. Our Site allows third-parties to sponsor, feature, advertise or promote their products. When this occurs, these products are clearly labelled and shown in a prominent position on the relevant page. Advertising may include hyperlinks to third-party websites and we may receive a payment for that advertising when you use those links. The inclusion of third-party advertisements on our website does not constitute a recommendation or endorsement by Stay or Go (or its respective directors, affiliates or employees) of the products, services or offers of the operator of the third-party website. We do not make any representations or warranties about the content, suitability or appropriateness of the content or products contained in any third-party website.

We also make money by introducing interested consumers to new credit providers and brokers or to different credit products issued by their existing credit provider or broker. Where you have been referred to a credit provider or broker via our Site, we may receive a fee or commission, including when you submit a credit application, or your application is approved. Fees and commissions are individually negotiated and may vary between credit providers and brokers.

### Conduct we don’t accept

When you use our Site, we expect you to abide by a certain standard of behaviour. You must not do or attempt to do anything that is unlawful, which is prohibited by any laws applicable to our Site, which we would consider inappropriate or which might bring us or our Site into disrepute. This includes:

1. impersonating another person or entity or falsely state your affiliation with a person or an entity;
2. misrepresenting, in any way, yourself by providing false, incomplete and/or misleading information;
3. falsely misrepresenting your age, employment, personal circumstances or financial circumstances; 
4. anything that would constitute a breach of an individual’s privacy (including uploading private or personal information without an individual's consent) or any other legal rights;
5. using our Site to defame, harass, threaten, menace or offend any person;
6. interfering with any user of our Site;
7. tampering with or modifying our Site, knowingly transmitting viruses or other disabling features, or damaging or interfering with our Site, including (without limitation) using trojan horses, viruses or piracy or programming routines that may damage or interfere with our Site;
8. using our Site to send unsolicited electronic messages; or
9. facilitating or assisting a third party to do any of the above acts.

We make no representation or warranty as to the availability of our Site or that your access to the Site will be uninterrupted. The security of our Site, and the information you share, is our priority, however we make no representations or warranty regarding security.

### Intellectual Property rights

Unless otherwise indicated, we own or licence all rights, title and interest (including intellectual property rights) in our Site and all of our Content, including our logos, trade marks and branding. Your use of our Site and your use of and access to any Content does not grant or transfer to you any rights, title or interest in relation to our Site or our Content, including to our logos, trade marks and branding. 

You must not, without prior written consent:

1. copy or use, in whole or in part, any Content, including our logos, trade marks and branding;
2. reproduce, retransmit, distribute, disseminate, sell, publish, broadcast or circulate any Content, including our logos, trade marks and branding, to any third party; or
3. breach any intellectual property rights connected with our Site or our Content, including our logos, trade marks and branding, and including (without limitation) altering or modifying any of our Content (logos, trade marks or branding), causing any of our Content (logos, trade marks or branding) to be framed or embedded in another website or platform, or creating derivative works from our Content (logos, trade marks or branding).

This clause survives termination of these Terms.

### Competitors are excluded from using our Site

You are prohibited from using our Site, including our Content, in any way that competes with our business.

### Content you upload

We encourage you to interact with our Site! You may be permitted to upload, submit or transmit relevant information and content (User Content) on our Site. If you make any User Content available on or through our Site, you grant to us a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free licence to use the User Content, with the right to use, view, copy, adapt, modify, distribute, license, transfer, communicate, publicly display, publicly perform, transmit, stream, broadcast, access, or otherwise exploit such User Content on, through or by means of our Site.

You agree that you are solely responsible for all User Content that you make available on or through our Site. You represent and warrant that:

1. you are either the sole and exclusive owner of all User Content or you have all rights, licences, consents and releases that are necessary to grant to us the rights in such User Content (as contemplated by these Terms); and
2. neither the User Content nor the posting, uploading, publication, submission or transmission of the User Content or our use of the User Content on, through or by means of our Site will infringe, misappropriate or violate a third party’s intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation. 

We do not endorse or approve, and are not responsible for, any User Content. We may, at any time (at our sole discretion), remove any User Content.

### Third party sites

Our Site may contain links to websites operated by third parties. Unless we tell you otherwise, we do not control, endorse or approve, and are not responsible for, the content on those websites. We recommend that you make your own investigations with respect to the suitability of those websites. Please refer to [how we make money](#how-we-make-money).

### Warranties and disclaimers

To the maximum extent permitted by law, we make no representations or warranties about our Site or our Content, including (without limitation) that:

1. they are complete, accurate, reliable, up-to-date and suitable for any particular purpose;
2. access will be uninterrupted, error-free or free from viruses; or
3. our Site will be secure. You read, use and act on our Site and our Content at your own risk.

You read, use, and act on our Site and the Content at your own risk.

You further acknowledge and understand that we are not party to, or responsible for, your relationship with the card issuers and you should conduct your own due diligence to assess a card issuer’s suitability for your financing needs.

### Our Liability is limited

To the maximum extent permitted by law, we are not responsible for any loss, damage or expense, howsoever arising, whether direct or indirect and/or whether present, unascertained, future or contingent (Liability) suffered by you or any third party, arising from or in connection with your use of our Site and/or our Content and/or any inaccessibility of, interruption to or outage of our Site and/or any loss or corruption of data and/or the fact that our Content is incorrect, incomplete or out-of-date.

This clause will survive termination of these Terms.

### Indemnity

To the maximum extent permitted by law, you must indemnify us, and hold us harmless, against any Liability suffered or incurred by us arising from or in connection with your use of our Site or any breach of these Terms or any applicable laws by you. This indemnity is a continuing obligation, independent from the other obligations under these Terms, and continues after these Terms end. It is not necessary for us to suffer or incur any Liability before enforcing a right of indemnity under these Terms.

This clause will survive termination of these Terms.

### What happens if we discontinue our Site

We may, at any time and without notice to you, discontinue our Site, in whole or in part. We may also exclude any person from using our Site, at any time and at our sole discretion. We are not responsible for any Liability you may suffer arising from or in connection with any such discontinuance or exclusion.

### Terminating these terms

These Terms are effective until terminated by us, which we may do at any time and without notice to you. In the event of termination, all restrictions imposed on you by these Terms and limitations of Liability set out in these Terms will survive.

We may immediately suspend, terminate or limit your access to and use of the Site if (i) you breach the Terms, any applicable laws, regulations or third party rights or (ii) if we have received complaints about you from card issuers.

### Dealing with a problem

If you would like to give us feedback, please contact us – we appreciate your input. 

In the event of any dispute arising from, or in connection with, these Terms (Dispute), the party claiming there is a Dispute must give written notice to the other party setting out the details of the Dispute and proposing a resolution. Notice can be sent to us using the email or postal address included at the end of these Terms. We will provide any notice to you using your last contact details provided to us. Within 7 days after receiving the notice, the parties must meet at least once to attempt to resolve the Dispute or agree on the method of resolving the Dispute by other means, in good faith. All aspects of every such conference, except the fact of the occurrence of the conference, will be privileged. If the parties do not resolve the Dispute, or (if the Dispute is not resolved) agree on an alternate method to resolve the Dispute, within 21 days after receipt of the notice, the Dispute may be referred by either party (by notice in writing to the other party) to litigation. Notwithstanding any other provision of these Terms, any party may commence court proceedings in relation to an urgent interlocutory application without meeting to resolve the Dispute.

### If a part of these Terms isn’t right

If a provision of these Terms is held to be void, invalid, illegal or unenforceable, that provision must be read down as narrowly as necessary to allow it to be valid or enforceable. If it is not possible to read down a provision (in whole or in part), that provision (or that part of that provision) is severed from these Terms without affecting the validity or enforceability of the remainder of that provision or the other provisions in these Terms.

### Which laws govern these terms of use

Your use of our Site and these Terms are governed by the laws of Victoria. You irrevocably and unconditionally submit to the exclusive jurisdiction of the courts operating in Victoria and any courts entitled to hear appeals from those courts and waive any right to object to proceedings being brought in those courts.

Our Site may be accessed throughout Australia and overseas. We make no representation that our Site complies with the laws (including intellectual property laws) of any country outside Australia. If you access our Site from outside Australia, you do so at your own risk and are responsible for complying with the laws of the jurisdiction where you access our Site.

**For any questions and notices, please contact us at:**

Stay or Go Pty Ltd ABN 50 637 744 613  
Email: <info@stayorgo.com.au>  
Mail: Stay or Go, Stone & Chalk, 121 King St, Melbourne VIC 3000

**Last update**: 29 February 2024

`

export default function Terms() {
  return (
    <Layout title="Terms of Use | Stay or Go" description="Before you browse our website, please read the Stay or Go Terms of Use, as they set out your rights and responsibilities when using our website." image="/images/Logo_SOG_Colour.png">
      <Markdown md={md} />
    </Layout>
  )
}
